import React from "react"
import { Container } from "reactstrap"
import Header from "./Header"
import Footer from "./Footer.hbd"

const Layout = ({ children }) => (
  <>
    <Header />
    <Container className="hbd-content-container main-main">{children}</Container>
    <Footer />
  </>
)

export default Layout
