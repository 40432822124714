import React, { useMemo } from "react"
import { Row, Col } from "reactstrap"
import { Link } from "react-router-dom"
import styled, { keyframes } from "styled-components"
import Markdown from "utils/Markdown"
import moment from "moment"
import userPlaceHolderImage from "assets/images/user-placeholder.hbd.jpg"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import nl2br from "utils/nl2br"
import * as styles from "./index.module.hbd.scss"
import marlinThumb from "assets/images/25hbd/2024/marlin.png"
import captainImg from "assets/images/25hbd/2024/captain.png"
import afficheImg from "assets/images/25hbd/2024/affiche-thumb.jpg"
import { Featured } from "./Featured"
import { Query } from "@apollo/react-components"
import { RandomRead } from "./RandomRead"

const Home = ({ currentEdition }) => {
  const now = useMemo(() => moment(), [])
  const theme = useQuery(
    gql`
      query Theme {
        currentEdition {
          id
          theme
        }
      }
    `,
    { fetchPolicy: "cache-only" }
  ).data.currentEdition.theme
  const period = useMemo(() => {
    const beginDate = moment(currentEdition.beginDate)
    const endDate = moment(currentEdition.endDate)
    if (now.isBefore(beginDate.clone().subtract(8, "hours"))) return "before"
    if (now.isBefore(beginDate)) return "day-before"
    if (now.isBefore(endDate)) return "during"
    if (now.isBefore(endDate.clone().add(11, "hours"))) return "day-after"
    return "after"
  }, [currentEdition])
  const randomReadYear =
    period === "day-after" || period === "after" ? currentEdition.year : currentEdition.year - 1
  return pug`
    .home-page
      .d-md-flex(style={ margin: "-1.5rem", marginBottom: "calc(-1.5rem - 4px)", fontSize: "0.95rem" })
        .flex-grow-1
          .home-page-main-block
            h1.mb-4
              | 25 Heures de la BD et de l'Illustration
            img.d-block.float-sm-left.mb-3.mb-sm-0.mx-auto.ml-sm-0.mr-sm-5(src=captainImg, alt="", width=170)
            Markdown= INTRO_TEXT_MD
            div(style={ clear: 'left' })
            if now.isAfter(moment(currentEdition.beginDate))
              .lead.showtime.p-3.text-center
                Markdown= theme || "Thème à venir"
            else
              .lead.p-3.py-4.text-center.mt-4.mb-4
                p.mb-1 La dixième édition des 25HBD aura lieu les #[strong 26 et 27 octobre] 2024 de #[strong 13h00 à 13h00] (heure française).
                p.m-0(style={ fontSize: "0.9em" })
                  | Inscriptions #[Link(to="/register") ici →]
        aside.my-md-5.text-center.border-left-sm-0.px-2.d-flex.d-md-block.justify-content-around.align-items-center.pb-5.pb-md-0
          Vip(src=marlinThumb links=[{ label: "Lire l'interview", to: "/blog/249563_interview-de-marlin-marraine-et-illustratrice-2024/" }], style={ maxWidth: 190 }) Marlin, illustratrice 2024
          div
            a.d-inline-block.mt-4.mx-auto(href="https://ddxgoij8mmg1v.cloudfront.net/content/Affiche+25hBD+2024.png", target="blank")
              img(src=afficheImg, alt="Affiche par Marlin", width=120)
            .mt-2(style={fontSize: "0.9em", lineHeight: "1.2em"})
              a(href="https://ddxgoij8mmg1v.cloudfront.net/content/Affiche+25hBD+2024.png", target="blank") Télécharger l'affiche de la 10ème édition
      RandomRead(year=randomReadYear)
  `
}

const INTRO_TEXT_MD = `
Les 25 Heures de la BD et de l'Illustration sont un marathon artistique qui se déroule en ligne.

Il faut créer une bande dessinée de 12 pages ou une série de 12 illustrations ou un webtoon de 24 panels, avec un thème et une contrainte imposés, dans un délai de 25 heures.

Tout le monde peut participer, quel que soit son niveau ou son âge.

Pas de jury, mais un compteur. Les plus rapides décrochent une tortue d'or, les retardataires une tortue d'argent et les frimeuses une tortue rouge.

Pour plus de détails sur le déroulement de l'événement, consulte le règlement.
`

function Vip({ src, to, href, children, links, placeholder, imgStyle, className, style }) {
  return pug`
    .vip(className=className, style=style)
      AnyLink(to=to)
        .vip-thumbnail
          img(src=(src || userPlaceHolderImage) alt=children style=imgStyle)
      .mt-2
        div
          AnyLink.link-unstyled(to=to href=href)
            span.vip-name= children
        each link, index in links
          AnyLink(key=index to=link.to href=link.href target=link.target)= link.label
  `
}

function AnyLink(props) {
  let ComponentToUse = "span"
  if (typeof props.href === "string") {
    ComponentToUse = "a"
  }
  if (typeof props.to === "string") {
    ComponentToUse = Link
  }
  return <ComponentToUse {...props} />
}

export default Home
