import React from "react"

export default () => (
  <React.Fragment>
    <h1 className="mb-5">Contact</h1>

    <div className="mb-4">
      <div>
        <strong>Mail de la Boss pour les demandes générales :</strong>
      </div>
      <div>
        Zia : <a href="mailto:zia@25hbd.com">zia@25hbd.com</a>
      </div>
    </div>

    <div className="mb-4">
      <div>
        <strong>Vous rencontrez un bug ou un problème avec le site :</strong>
      </div>
      <ul>
        <li>
          Mail à <a href="mailto:dev@23hbd.com">dev@23hbd.com</a> (c'est bien @23hbd.com et pas 25
          car adresse mail commune pour les 2 sites)
        </li>
        <li>Ou envoyez un message à Ugo sur Discord (lien du serveur en dessous)</li>
      </ul>
    </div>

    <div className="mb-4">
      <div>
        <strong>Vous pouvez également contacter l'équipe sur le Discord :</strong>
      </div>
      <div>
        Serveur Discord :{" "}
        <a href="https://discord.gg/c6BwhC8" target="blank">
          https://discord.gg/c6BwhC8
        </a>
      </div>
    </div>

    <div className="mb-4">
      <div>
        <strong>Ou sur le groupe Facebook :</strong>
      </div>
      <div>
        Page 25HBD :{" "}
        <a href="https://www.facebook.com/groups/326420895466613" target="blank">
          Groupe des 23HBD & 25HBD- Marathons BD, illustration, webtoon, turbomedia
        </a>
      </div>
    </div>
  </React.Fragment>
)
