import React, { useEffect, useMemo, useRef, useState } from "react"
import { createPortal } from "react-dom"
import Lightbox from "react-images"
import { Container } from "reactstrap"
import useReadStatuses from "utils/useReadStatuses"
import NSFWDisclaimer from "../NSFWDisclaimer"
import { useIsNSFWHidden } from "../useIsNSFWHidden"
import PreloadTurbomediaIfNeeded from "./PreloadTurbomediaIfNeeded"

function LightboxViewer({ participation, currentPage, setCurrentPage, onClose, headContent }) {
  const currentPageNumber = currentPage
  const currentPageIndex = currentPageNumber ? currentPageNumber - 1 : undefined
  const { readPage } = useReadStatuses()
  useEffect(() => {
    readPage(participation.id, currentPageNumber)
  }, [currentPageNumber])
  const isNSFWHidden = useIsNSFWHidden(participation)
  const pagesToShow = useMemo(
    () =>
      participation.pages.map(({ url }) => ({ src: url }))
      |> ((pages) => (isNSFWHidden ? pages.slice(0, 1) : pages)),
    [participation.pages]
  )
  return (
    <PreloadTurbomediaIfNeeded isOpen participation={participation}>
      <Lightbox
        images={pagesToShow}
        isOpen
        onClickPrev={() => {
          setCurrentPage(currentPageNumber - 1)
        }}
        onClickNext={() => {
          setCurrentPage(currentPageNumber + 1)
        }}
        currentImage={currentPageIndex}
        onClose={onClose}
        backdropClosesModal={true}
        imageCountSeparator={` ${t(`participation-page.planches.lightbox-image-count-separator`)} `}
        width={1200}
        showImageCount={!isNSFWHidden}
      />
      {headContent && (
        <HeadContentContainer key={currentPageIndex}>{headContent}</HeadContentContainer>
      )}

      {isNSFWHidden &&
        createPortal(
          <div style={{ position: "fixed", top: 20, left: 0, right: 0, zIndex: 2002 }}>
            <Container>
              <NSFWDisclaimer />
            </Container>
          </div>,
          document.body
        )}
    </PreloadTurbomediaIfNeeded>
  )
}

function HeadContentContainer({ children }) {
  const imageNode = useAwaitedLightboxImage()
  if (!imageNode) return null
  const { left, top, width } = imageNode.getBoundingClientRect()
  return createPortal(
    <div
      style={{
        position: "fixed",
        zIndex: 2002,
        bottom: window.innerHeight - top,
        left,
        width: width - 100,
        color: "white",
      }}
      className="pb-2"
    >
      {children}
    </div>,
    document.body
  )
}

function useAwaitedLightboxImage() {
  const [node, setNode] = useState(null)
  const initialNode = document.querySelector("#lightboxBackdrop figure img")
  const countRef = useRef(0)
  useEffect(() => {
    let interval
    if (!initialNode) {
      interval = setInterval(() => {
        if (countRef.current++ > 60) {
          // 3 seconds
          clearInterval(interval)
          return
        }
        const nodeNow = document.querySelector("#lightboxBackdrop figure img")
        if (nodeNow && nodeNow.getBoundingClientRect().width > 0) {
          setNode(nodeNow)
          clearInterval(interval)
        }
      }, 50)
    }
    return () => {
      clearInterval(interval)
    }
  }, [])
  return node || initialNode
}

export default LightboxViewer
